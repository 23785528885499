import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { SearchFieldComponent } from './search-field.component';

@NgModule({
  declarations: [SearchFieldComponent],
  imports: [MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule],
  exports: [SearchFieldComponent],
})
export class SearchFieldModule {}
