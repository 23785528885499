import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { SearchFieldModule } from '@falcon/shared/components/search-field';
import { PCCodeSelectComponent } from './pccode-select.component';
import { SearchPCCodeDialogComponent } from './components/search-pccode-dialog';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatRippleModule,
    SearchFieldModule,
    MatProgressSpinnerModule,
  ],
  exports: [PCCodeSelectComponent],
  declarations: [PCCodeSelectComponent, SearchPCCodeDialogComponent],
})
export class PCCodeSelectModule {}
