import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SearchFieldModule } from '@falcon/app/shared/components/search-field';
import { FunctionPositionFieldComponent } from './function-position-field.component';
import { FunctionPositionSearchComponent } from './components/function-position-search/function-position-search.component';

@NgModule({
  declarations: [FunctionPositionFieldComponent, FunctionPositionSearchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    SearchFieldModule,
    TranslateModule,
    MatRippleModule,
    MatProgressSpinnerModule,
  ],
  exports: [FunctionPositionFieldComponent],
})
export class FunctionPositionFieldModule {}
