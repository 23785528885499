import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostBinding,
  Attribute,
  Optional,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DebounceTypeEnum } from '@falcon/shared/enums';

@UntilDestroy()
@Component({
  selector: 'falcon-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SearchFieldComponent {
  @Input() placeholder!: string;
  @Output() search = new EventEmitter<string>();

  searchControl = new UntypedFormControl('');

  get searchValue(): string {
    return this.searchControl.value;
  }

  get isInputSearchMode(): boolean {
    return this.searchOnInput !== null;
  }

  @HostBinding('class') hostClasses = ['falcon-search-field'];

  constructor(@Optional() @Attribute('searchOnInput') private searchOnInput: string | null) {
    if (this.isInputSearchMode) {
      this.searchControl.valueChanges
        .pipe(
          debounceTime(DebounceTypeEnum.Typing),
          map(value => value.trim()),
          distinctUntilChanged(),
          untilDestroyed(this)
        )
        .subscribe(value => this.search.emit(value));
    }
  }

  submitSearch(): void {
    if (!this.isInputSearchMode && this.searchValue.trim()) {
      this.search.emit(this.searchValue);
    }
  }
}
