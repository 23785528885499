import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
  imports: [CommonModule, MatMenuModule, MatButtonModule],
  exports: [LanguageSelectorComponent],
  declarations: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
