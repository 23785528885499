<mat-form-field>
  <input
    matInput
    type="text"
    autocorrect="off"
    enterkeyhint="search"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    (keydown.enter)="submitSearch()"
  />

  <button matIconSuffix mat-icon-button color="primary" (click)="submitSearch()">
    <i class="falcon-icon falcon-icon-search"></i>
  </button>
</mat-form-field>
