import { Component, ChangeDetectionStrategy, Inject, OnInit, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DictionaryItem } from '@falcon/shared/models';
import { dictionaryItemTrackByFn } from '@falcon/shared/constants';

@Component({
  selector: 'falcon-search-pccode-dialog',
  templateUrl: './search-pccode-dialog.component.html',
  styleUrls: ['./search-pccode-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPCCodeDialogComponent implements OnInit {
  pcCodeSearchValue$ = new BehaviorSubject<string>('');
  filteredCodes$!: Observable<DictionaryItem[]>;
  dictionaryItemTrackByFn = dictionaryItemTrackByFn;

  @HostBinding('class.falcon-search-pccode-dialog') hostClass = true;

  constructor(
    private matDialogRef: MatDialogRef<SearchPCCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private pcCodes$: Observable<DictionaryItem[]>
  ) {}

  ngOnInit(): void {
    this.filteredCodes$ = combineLatest([this.pcCodes$, this.pcCodeSearchValue$]).pipe(
      map(([pcCodes, searchValue]) => pcCodes.filter(this.filterPCCodes(searchValue)))
    );
  }

  close(item: DictionaryItem): void {
    this.matDialogRef.close(item);
  }

  private filterPCCodes(value: string): (pccode: DictionaryItem) => boolean {
    return (pccode: DictionaryItem) =>
      Object.values(pccode).some(property => property.toLowerCase().includes(value.toLowerCase()));
  }
}
